<template>
	<div></div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'Index',
	computed: {
		...mapState('auth', {
			isLoggedIn: 'isLoggedIn',
		}),
		...mapGetters({
			getDefaultRouteName: 'profile/getDefaultRouteName',
		}),
	},
	created() {
		const routeName = this.isLoggedIn ? this.getDefaultRouteName : ROUTE_NAME.LOGIN;
		this.$router.push({ name: routeName });
	},
};
</script>